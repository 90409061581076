//  ===========================================
//  Typography
//  ===========================================

html,
body,
p,
ul,
h1,
h2,
h3,
h4,
h5,
span,
.button {
	font-family: $font-primary;
}

h3 {
	font-weight: 700;
	font-size: 20px;
	color: #fff;
	margin: 0 0 20px;
}

//  ===========================================
//  API Bar
//  ===========================================

#api-bar {
	width: 100%;
	height: 70px;
	background: $color-blue--light;
	display: flex;
	flex-direction: row;
	justify-content: center;
	box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.5);
	p {
		color: #fff;
		margin-right: 10px;
		font-size: 15px;
		align-self: center;
		a {
			@include underline-link();
		}
	}
	a {
		align-self: center;
	}
	form {
		align-self: center;
	}
	.api {
		background: #fff;
		color: $color-blue--dark;
		padding: 8px;
		font-size: 16px;
	}
}

//  ===========================================
//  API Form
//  ===========================================

#apiForm {
	input {
		border: 0;
		outline: 0;
		font-size: 14px;
		padding: 10px;
		border: 3px solid $color-blue--light;
		transition: border 0.3s ease-in;
		width: 280px;
		margin-right: 10px;
		&:focus {
			border: 3px solid $color-blue--dark;
		}
	}
}

//  ===========================================
//  Postcode Form
//  ===========================================

#postcodeForm {
	input {
		border: 0;
		outline: 0;
		font-size: 36px;
		letter-spacing: 7px;
		font-weight: bold;
		margin: 0;
		padding: 0 10px;
		height: 60px;
		transition: border 0.3s ease-in;
		width: 260px;
		text-transform: uppercase;
	}
	.button {
		margin: 0;
		height: 60px;
		position: relative;
		top: -8px;
	}
}

//  ===========================================
//  Buttons
//  ===========================================

.button {
	display: inline-block;
	padding: 8px 12px;
	text-decoration: none;
	color: #fff;
	// margin-right: 10px;
	font-size: 15px;
	font-weight: bold;
	transition: all 0.2s ease-in;
	&--clear {
		border: 2px solid $color-red;
		&:hover {
			text-decoration: none;
			border: 2px solid darken($color-red, 10%);
			background: $color-red;
		}
	}
	&--submit {
		background: $color-orange;
		border: 0;
		outline: 0;
		color: $color-blue--dark;
		&:hover {
			cursor: pointer;
			color: #fff;
			background: $color-green;
		}
	}
}

//  ===========================================
//  Footer & Error
//  ===========================================

footer p,
.error,
.success {
	text-align: center;
	color: #fff;
	font-size: 15px;
	margin: 0;
	a {
		@include underline-link();
	}
}

//  ===========================================
//  Error
//  ===========================================

.error {
	background: $color-red;
	box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.5);
	p {
		margin: 0;
	}
}

//  ===========================================
//  Success
//  ===========================================

.success {
	background: $color-green;
	box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.5);
	p {
		margin: 0;
	}
}

//  ===========================================
//  Postcode Search
//  ===========================================

.postcode-search {
	align-self: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	&__logo {
		width: 440px;
		height: 120px;
		margin-bottom: 20px;
	}
	&__perspective {
		perspective: 800px;
		display: inline-block;
		margin-top: 20px;
	}
	&__button {
		@extend .button;
		display: flex;
		color: $color-blue--dark;
		background: $color-orange;
		svg {
			margin-left: 5px;
			width: 20px;
			height: 20px;
			fill: $color-blue--dark;
			transition: all 0.2s ease-in;
		}
		&:hover {
			color: #fff;
			background: $color-green;
			svg {
				fill: #fff;
			}
		}
	}
}

//  ===========================================
// Loader when searching for postcode
//  ===========================================

.loader {
	margin: 5rem auto;
	text-align: center;
	svg {
		height: 50px;
		width: 58px;
		fill: $color-orange;
		transform-origin: 44% 50%;
		animation: rotate 1.5s infinite linear;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

//  ===========================================
// Title Bar
//  ===========================================

.title-bar {
	.logo {
		align-self: center;
	}
	.info {
		text-align: right;
		align-self: center;
		.date {
			font-size: 16px;
			color: $color-orange;
			font-weight: 700;
			margin: 0;
		}
		.time {
			font-size: 36px;
			color: #fff;
			margin: 0;
		}
	}
}

//  ===========================================
//  Weather & Location Boxes
//  ===========================================

.row {
	border-bottom: 1px solid $color-grey;
	flex: 1;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	p.small {
		font-size: 14px;
		color: $color-blue--light;
		margin: 0;
	}
	p.big {
		font-size: 30px;
		color: $color-blue--dark;
		margin: 0;
	}
}

.summary {
	.icon {
		align-self: center;
	}
	.temp {
		font-size: 50px;
		color: $color-blue--dark;
		margin: 0;
		font-weight: bold;
		margin-left: 15px;
		align-self: baseline;
	}
	.unit {
		font-size: 24px;
		color: $color-blue--dark;
		margin: 0;
		font-weight: bold;
		align-self: baseline;
	}
	.text {
		margin-left: 15px;
		align-self: baseline;
	}
}
